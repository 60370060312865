<template>
	<div class="news">
		<div class="learn-head">
			<div class="head-box">
				<div class="text-name">
					<div class="text-eng">SEARCH</div>
					<div class="text-clan">搜索</div>
				</div>
				<div class="page-route">首页/搜索列表</div>
			</div>
		</div>
		<div class="learn-box"></div>
		<div class="news-list">
			<ul class="news-list-box">
				<li v-for="(item, i) in newlist" :key="i" @click="details(item, i)">
					<div>
						<p>{{ item.publishTime.split(' ')[0].split('-')[2] }}</p>
						<p>{{ item.publishTime.split(' ')[0].split('-')[0] + '-' + item.publishTime.split(' ')[0].split('-')[1] }}</p>
					</div>
					<div>
						<p>{{ item.noticeTitle }}</p>
						<p class="el-icon-right"></p>
					</div>
				</li>
			</ul>
			<div v-if="newlist.length == 0" class="noTips">暂无数据...</div>
			<!-- <div class="pagin-box">
				<el-pagination
					layout="prev, pager, next"
					:page-size="20"
					:total="4"
					@prev-click="prevclick"
					@next-click="nextclick"
					@current-change="currentchange"
				></el-pagination>
			</div> -->
		</div>
	</div>
</template>
<script>
import { listInfoByInfoName } from '@/api/api.js'
export default {
	data() {
		return {
			parentabs: this.$store.state.tabs, //父级路由信息
			tabList: [],
			newlist: [],
			tabLen: 0,
			headText: '',
			columnId: '',
			total: 0,
			pageSize: 20,
			pageNum: 1,
			columnName: ''
		}
	},
	props: {},
	components: {},
	created() {},
	computed: {},
	watch: {
		'$route.query.name': {
			handler(newVal) {
				console.log(newVal)
				if (newVal) {
					this.Getcolumnitem(newVal)
				} else {
					this.$message.error('请输入搜索内容！')
				}
			}
		}
	},
	mounted() {
		console.log(this.$route.query.name, '-------------------')
		this.columnId = this.$store.state.tabs.columnId
		this.Getcolumnitem(this.$route.query.name)
	},
	methods: {
		prevclick(num) {
			this.pageNum = num
			//上一页
			this.Getcolumnitem()
		},
		currentchange(num) {
			this.pageNum = num
			this.Getcolumnitem()
		},
		nextclick(num) {
			this.pageNum = num
			//下一页
			this.Getcolumnitem()
		},
		learnClick(item, i) {
			this.tabLen = i
			this.headText = item.columnName
			this.columnId = item.columnId

			this.Getcolumnitem(item.columnId)
			//this.$store.commit('setTabs', item)
		},
		details(item, i) {
			this.$router.replace({
				path: '/newdetaile',
				query: {
					id: item.noticeId
				}
			})
		},
		Getcolumnitem(name) {
			let params = {
				infoName: name
			}
			listInfoByInfoName(params)
				.then(res => {
					console.log('22222')
					res.forEach(item => {
						!item.publishTime && (item.publishTime = item.createTime)
					});
					this.newlist = res
				})
				.catch(error => {
					this.$message.error('搜索失败！')
				})
		}
	}
}
</script>
<style scoped lang="scss">
.news {
	width: 100%;
	padding-bottom: 50px;
	.learn-box {
		width: 100%;
		padding-top: 50px;
		.learn-list {
			margin: 0 auto;
			width: 1330px;
			height: 80px;
			border: 1px solid #d4e6e6;
			display: flex;
			flex-direction: row;
			li {
				flex: 3;
				text-align: center;
				line-height: 80px;
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #666666;
				cursor: pointer;
			}
			.learn-active {
				background: #d4e6e6;
				color: #237e80;
			}
		}
	}
	.news-list {
		width: 100%;
		padding-top: 52px;
		.news-list-box {
			width: 1330px;
			margin: 0 auto;
			li {
				width: 100%;
				overflow: hidden;
				div:nth-child(1) {
					background: #f1f1f1;
					width: 120px;
					height: 120px;
					margin-top: 20px;
					float: left;
					p:nth-child(1) {
						margin-top: 23px;
						font-size: 48px;
						font-family: Source Han Sans CN;
						font-weight: bold;
						color: #333333;
						line-height: 48px;
					}
					p:nth-child(2) {
						font-size: 18px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #666666;
						line-height: 18px;
						padding-top: 10px;
					}
				}
				div:nth-child(2) {
					width: 1098px;
					float: right;
					text-align: left;
					margin-left: 52px;
					display: flex;
					justify-content: space-between;
					border-bottom: 1px solid #ccc;
					padding: 65px 0px 45px 60px;
					p:nth-child(1) {
						font-size: 22px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #333333;
						line-height: 48px;
						padding-right: 190px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					p:nth-child(2) {
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 30px;
					}
				}
			}
			li:hover {
				cursor: pointer;
				background: #237e80;
			}
			li:hover div:nth-child(1) {
				background: none;
			}
			li:hover div:nth-child(2) {
				border: none;
			}
			li:hover div:nth-child(1) p {
				color: #fff;
			}
			li:hover div:nth-child(2) p {
				color: #fff;
			}
			li:hover div:nth-child(2) p:nth-child(2) {
				padding-right: 20px;
			}
		}
		.pagin-box {
			padding-top: 84px;
		}
	}
}
::v-deep .btn-prev {
	background: #f1f1f1 !important;
	border-radius: 50% !important;
	width: 40px !important;
	height: 40px !important;
	padding: 0px !important;
}
::v-deep .btn-prev:hover {
	background: #237e80 !important;
	color: #fff;
}
::v-deep .btn-next {
	background: #f1f1f1 !important;
	border-radius: 50% !important;
	width: 40px !important;
	height: 40px !important;
	padding: 0px !important;
}
::v-deep .btn-next:hover {
	background: #237e80 !important;
	color: #fff;
}
::v-deep .el-pager {
	margin-top: 8px;
	margin-left: 0px;
	margin-right: 10px;
}

.learn-head {
	width: 100%;
	height: 260px;
	background: url(../../static/images/VCG211417596978.png) no-repeat center;
}
.head-box {
	width: 1330px;
	height: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	.text-name {
		text-align: left;
		.text-eng {
			font-size: 40px;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: #ffffff;
			line-height: 40px;
			margin-top: 82px;
		}
		.text-clan {
			font-size: 40px;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: #ffffff;
			line-height: 40px;
			position: relative;
			padding-top: 15px;
		}
	}
	.page-route {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #ffffff;
		line-height: 14px;
		display: flex;
		flex-direction: column-reverse;
		padding-bottom: 55px;
	}
}
.noTips {
	margin-bottom: 20px;
	text-align: center;
	font-size: 16px;
	color: #ccc;
}
</style>
